import { defineStore } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgParagraphService } from '../../apiClient/services'
import { computed } from 'vue'
import { createObjectsMap } from '@/utils'
import { ESG_STANDARD, IEsgParagraph } from '@/apiClient/types/esg'

export const useEsgParagraphStore = defineStore('esgParagraph', () => {
  const socketService = useSocketsCrudFactory(
    esgParagraphService,
    'esgParagraph',
  )

  const esgParagraphMap = computed(() =>
    createObjectsMap(socketService.list.value, 'id'),
  )
  const esgParagraphsByStandardAndResourceId = computed<
    Record<ESG_STANDARD, Record<string, IEsgParagraph>>
  >(() => {
    const map: Record<
      ESG_STANDARD,
      Record<string, IEsgParagraph>
    > = {} as Record<ESG_STANDARD, Record<string, IEsgParagraph>>
    socketService.list.value.forEach((paragraph: IEsgParagraph) => {
      if (!map[paragraph.esgStandard]) {
        map[paragraph.esgStandard] = {}
      }
      map[paragraph.esgStandard][paragraph.resourceId] = paragraph
    })
    return map
  })

  return {
    ...socketService,
    esgParagraphMap,
    esgParagraphsByStandardAndResourceId,
  }
})
