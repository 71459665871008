import { defineStore, storeToRefs } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgRequirementService } from '../../apiClient/services'
import { useEsgProjectAnswerStore } from '../esgProjectAnswer'
import { computed } from 'vue'
import { createObjectsArrayMap, createObjectsMap } from '@/utils'
import { useEsgTagStore } from '../esgTag'
import {
  ESG_TAG_TYPE,
  IEsgParagraph,
  IEsgRequirement,
  IEsgRequirementAnswerConfig,
} from '@/apiClient/types/esg'
import { useEsgParagraphStore } from '../esgParagraph'

export const useEsgRequirementStore = defineStore('esgRequirement', () => {
  const socketService = useSocketsCrudFactory(
    esgRequirementService,
    'esgRequirement',
  )

  //#region ESG Tags
  const esgTagStore = useEsgTagStore()
  const { list: esgTags } = storeToRefs(esgTagStore)
  //#endregion

  //#region ESG Tags
  const esgParagraphStore = useEsgParagraphStore()
  const { esgParagraphsByStandardAndResourceId } =
    storeToRefs(esgParagraphStore)
  //#endregion

  //#region ESG Project Answer
  const esgProjectAnswerStore = useEsgProjectAnswerStore()
  const { list: esgProjectAnswers } = storeToRefs(esgProjectAnswerStore)
  //#endregion

  // /**
  //  * Map for PARAGRAPH tags, indexed by sourceId
  //  * @type {ComputedRef<Record<string, EsgTag[]>>}
  //  */
  // const esgTagsParagraphBySourceIdMap = computed(() => {
  //   return createObjectsArrayMap(
  //     esgTags.value.filter(({ type }) => type === ESG_TAG_TYPE.PARAGRAPH),
  //     'sourceId',
  //   )
  // })

  /**
   * Map for DEPENDS_ON tags, indexed by targetId
   * @type {ComputedRef<Record<string, EsgTag[]>>}
   */
  const esgTagsDependsOnByTargetIdMap = computed(() =>
    createObjectsArrayMap(
      esgTags.value.filter(({ type }) => type === ESG_TAG_TYPE.DEPENDS_ON),
      'targetId',
    ),
  )

  /**
   * Map of subrequirements, indexed by ID of the parent requirement
   * @type {ComputedRef<Record<string, Subrequirement[]>>}
   */
  const subrequirementMap = computed(() =>
    createObjectsArrayMap(
      socketService.list.value.map((requirement) => {
        const { answerConfigWithParagraphs, uniqueRequirementParagraphs } =
          prefillAnswerConfigsWithParagraphs(requirement.answerConfig || [])

        return {
          ...requirement,
          _dependsOn: (
            esgTagsDependsOnByTargetIdMap.value[requirement.id] || []
          ).map(({ sourceId }) => esgRequirementMap.value[sourceId]),
          answerConfig: answerConfigWithParagraphs,
          _paragraphs: uniqueRequirementParagraphs,
        }
      }),
      'parentRequirementId',
    ),
  )

  // ID map for dependencies for better performance
  /**
   * Map of ESG requirements, indexed by requirementGroupId
   * @type {ComputedRef<Record<string, EsgRequirement[]>>}
   */
  const esgRequirementByGroupIdMap = computed(() =>
    createObjectsArrayMap(esgRequirements.value, 'esgRequirementGroupId'),
  )

  /**
   * ID map for dependencies for better performance
   * @type {ComputedRef<Record<string, ProjectAnswer[]>>}
   */
  const projectAnswerMap = computed(() =>
    createObjectsArrayMap(esgProjectAnswers.value, 'esgRequirementId'),
  )

  /**
   * Map of ESG requirements, indexed by ID
   * @type {ComputedRef<Record<string, EsgRequirement>>}
   */
  const esgRequirementMap = computed(() =>
    createObjectsMap(socketService.list.value, 'id'),
  )

  /**
   * Map of ESG requirements, indexed by resourceId
   * @type {ComputedRef<Record<string, EsgRequirement>>}
   */
  const esgRequirementByResourceId = computed(() =>
    createObjectsMap(socketService.list.value, 'resourceId'),
  )

  const prefillAnswerConfigsWithParagraphs = (
    requirementAnswerConfig: IEsgRequirementAnswerConfig[],
    subrequirements: IEsgRequirement[] = [],
  ) => {
    const requirementParagraphs: IEsgParagraph[] = []

    const attachAnswerConfigParagraphs = (
      answerConfig: IEsgRequirementAnswerConfig,
    ): IEsgRequirementAnswerConfig => {
      const paragraphs = (answerConfig.paragraphs
        ?.map(({ esgStandard, resourceId, isRelated }) => {
          const paragraph =
            esgParagraphsByStandardAndResourceId.value?.[esgStandard]?.[
              resourceId
            ]
          if (!paragraph) return

          return {
            ...paragraph,
            _isRelated: isRelated,
          }
        })
        ?.filter(Boolean) || []) as IEsgParagraph[]

      requirementParagraphs.push(...paragraphs)

      return {
        ...answerConfig,
        _paragraphs: paragraphs,
        ...(answerConfig.children
          ? {
              children: answerConfig.children?.map(
                attachAnswerConfigParagraphs,
              ),
            }
          : {}),
      }
    }

    const answerConfigWithParagraphs = requirementAnswerConfig?.map(
      attachAnswerConfigParagraphs,
    )

    const uniqueRequirementParagraphs = Array.from(
      new Map(
        [
          ...subrequirements.flatMap(({ _paragraphs }) => _paragraphs || []),
          ...requirementParagraphs,
        ].map((paragraph) => [paragraph.id, paragraph]),
      ).values(),
    )

    return {
      answerConfigWithParagraphs,
      uniqueRequirementParagraphs,
    }
  }

  const esgRequirements = computed<IEsgRequirement[]>(() =>
    socketService.list.value
      .filter((requirement) => !requirement.parentRequirementId) // Only top-level requirements
      .map((requirement) => {
        const tagsDependsOn =
          esgTagsDependsOnByTargetIdMap.value[requirement.id] || []

        const { answerConfigWithParagraphs, uniqueRequirementParagraphs } =
          prefillAnswerConfigsWithParagraphs(
            requirement.answerConfig || [],
            subrequirementMap.value[requirement.id] || [],
          )

        return {
          ...requirement,
          answerConfig: answerConfigWithParagraphs,
          _subRequirements: subrequirementMap.value[requirement.id] || [],
          _dependsOn: tagsDependsOn.map(
            ({ sourceId }) => esgRequirementMap.value[sourceId],
          ),
          _paragraphs: uniqueRequirementParagraphs,
        }
      }),
  )

  return {
    ...socketService,
    esgRequirements,
    esgRequirementMap,
    projectAnswerMap,
    esgRequirementByGroupIdMap,
    esgRequirementByResourceId,
  }
})
