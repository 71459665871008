<template>
  <ul class="UILazyRender">
    <slot name="item" v-for="item in visibleItems" :item="item" />
    <div class="UILazyRender__obs" ref="observerTarget" />
  </ul>
</template>

<script lang="ts" setup generic="T">
import { computed, ref, onMounted, onUnmounted } from 'vue'
const props = withDefaults(
  defineProps<{
    items: T[]
    portionCount: number
  }>(),
  {
    items: () => [],
    portionCount: 6,
  },
)

const visibleCount = ref(props.portionCount)
const observerTarget = ref(null)
const observer = ref<IntersectionObserver | null>(null)

const visibleItems = computed<T[]>(() => {
  return props.items.slice(0, visibleCount.value)
})

const onIntersect = (entries: IntersectionObserverEntry[]) => {
  if (entries[0].isIntersecting) {
    visibleCount.value = Math.min(
      visibleCount.value + props.portionCount,
      props.items.length,
    )
  }
}

onMounted(() => {
  if (!observerTarget.value) return

  observer.value = new IntersectionObserver(onIntersect)
  observer.value.observe(observerTarget.value)
})

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect()
  }
})
</script>

<style scoped lang="scss">
.UILazyRender {
}
</style>
