<template>
  <Teleport to="#AppContainer">
    <transition name="modal">
      <UIDrawerOverlay v-if="isOpen" @mousedown.self="handleClose">
        <transition name="slide-fade-left">
          <div
            v-if="isContentVisible"
            v-bind="$attrs"
            :class="['UIDrawer', `UIDrawer--${size}`, $attrs['class']]"
          >
            <slot name="title-container">
              <UIDrawerTitle @close="$emit('close')">
                <slot name="title" />
              </UIDrawerTitle>
            </slot>

            <slot name="content-container">
              <UIDrawerContent>
                <slot name="content" />

                <template v-if="$slots['actions']" #actions>
                  <slot name="actions" />
                </template>
              </UIDrawerContent>
            </slot>
          </div>
        </transition>
      </UIDrawerOverlay>
    </transition>
  </Teleport>
</template>

<script lang="ts" setup>
import UIDrawerOverlay from './UIDrawerOverlay.vue'
import UIDrawerTitle from './UIDrawerTitle.vue'
import UIDrawerContent from './UIDrawerContent.vue'
import { ref, watch } from 'vue'

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(
  defineProps<{
    isOpen?: boolean
    closeOnClickOutside?: boolean
    size?: 'sm' | 'md' | 'lg'
  }>(),
  {
    isOpen: false,
    closeOnClickOutside: true,
    size: 'md',
  },
)
const emit = defineEmits(['close'])

const isContentVisible = ref(false)

watch(
  () => props.isOpen,
  (value) => {
    if (value) {
      setTimeout(() => {
        isContentVisible.value = value
      }, 100)
    } else {
      isContentVisible.value = value
    }
  },
  { immediate: true },
)

const handleClose = () => {
  if (props.closeOnClickOutside) {
    emit('close')
  }
}
</script>

<style scoped lang="scss">
.UIDrawer {
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  border-left: 1px solid $newcolor-border-light;
  background-color: $color-white;
  display: flex;
  flex-direction: column;

  @include md {
    &--sm {
      max-width: 30rem;
    }
    &--md {
      max-width: 45rem;
    }
    &--lg {
      max-width: 60rem;
    }
  }
}
</style>
