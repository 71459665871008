import { RouteRecordRaw } from 'vue-router'

export const pocRoutes: RouteRecordRaw[] = [
  {
    path: '/poc/buttons',
    name: 'POCButtons',
    component: () => import('@/views/pages/co2/POC/sections/POCButtons.vue'),
  },
  {
    path: '/poc/typography',
    name: 'POCTypography',
    component: () => import('@/views/pages/co2/POC/sections/POCTypography.vue'),
  },
  {
    path: '/poc/icons',
    name: 'POCIcons',
    component: () => import('@/views/pages/co2/POC/sections/POCIcons.vue'),
  },
  {
    path: '/poc/inputs',
    name: 'POCInputs',
    component: () => import('@/views/pages/co2/POC/sections/POCInputs.vue'),
  },
  {
    path: '/poc/dropdowns',
    name: 'POCDropdowns',
    component: () => import('@/views/pages/co2/POC/sections/POCDropdowns.vue'),
  },
  {
    path: '/poc/blocks',
    name: 'POCBlocks',
    component: () => import('@/views/pages/co2/POC/sections/POCBlocks.vue'),
  },
  {
    path: '/poc/form-wizard',
    name: 'POCFormWizard',
    component: () => import('@/views/pages/co2/POC/sections/POCFormWizard.vue'),
  },
  {
    path: '/poc/grid',
    name: 'POCGrid',
    component: () => import('@/views/pages/co2/POC/sections/POCGrid.vue'),
  },
  {
    path: '/poc/modals',
    name: 'POCModals',
    component: () => import('@/views/pages/co2/POC/sections/POCModals.vue'),
  },
  {
    path: '/poc/drawer',
    name: 'POCDrawer',
    component: () => import('@/views/pages/co2/POC/sections/POCDrawer.vue'),
  },
  {
    path: '/poc/tabs',
    name: 'POCTabs',
    component: () => import('@/views/pages/co2/POC/sections/POCTabs.vue'),
    children: [
      {
        path: 'test1',
        name: 'POCTabsTest1',
        component: () => import('@/views/pages/co2/POC/sections/test1.vue'),
      },
      {
        path: 'test2',
        name: 'POCTabsTest2',
        component: () => import('@/views/pages/co2/POC/sections/test2.vue'),
      },
    ],
  },
  {
    path: '/poc/table',
    name: 'POCTable',
    component: () => import('@/views/pages/co2/POC/sections/POCTable.vue'),
  },
  {
    path: '/poc/section',
    name: 'POCSection',
    component: () => import('@/views/pages/co2/POC/sections/POCSection.vue'),
  },
  {
    path: '/poc/users',
    name: 'POCUsers',
    component: () => import('@/views/pages/co2/POC/sections/POCUsers.vue'),
  },
  {
    path: '/poc/api-explorer',
    name: 'POCApiExplorer',
    component: () =>
      import('@/views/pages/co2/POC/sections/POCApiExplorer.vue'),
  },
  {
    path: '/poc/playground',
    name: 'POCPlayground',
    component: () => import('@/views/pages/co2/POC/sections/POCPlayground.vue'),
  },
  {
    path: '/poc/answer-configs',
    name: 'POCAnswerConfigs',
    component: () =>
      import('@/views/pages/co2/POC/sections/POCAnswerConfigs.vue'),
  },
  {
    path: '/poc/swagger',
    name: 'POCSwagger',
    component: () =>
      import('@/views/pages/co2/POC/sections/POCSwagger.vue'),
  },
  {
    path: '/poc/notifications',
    name: 'POCNotifications',
    component: () =>
      import('@/views/pages/co2/POC/sections/POCNotifications.vue'),
  },
]
