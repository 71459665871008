<template>
  <TheSidebar :isOpen="isSidebarOpen" @close="isSidebarOpen = false" />

  <div class="LayoutDashboard__main">
    <TheTopbar @toggleSidebar="isSidebarOpen = !isSidebarOpen" />
    <UIBreadcrumbs class="LayoutDashboard__breadcrumbs" />

    <div class="LayoutDashboard__container">
      <RouterView />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, watch } from 'vue'
import TheSidebar from '@/views/components/ui/navigation/TheSidebar'
import TheTopbar from '@/views/components/ui/navigation/TheTopbar'
import UIBreadcrumbs from '../components/ui/navigation/UIBreadcrumbs.vue'
import { useAppStore } from '@/store/app'
import { useUserStore } from '@/store/user'
import { useOrgStore } from '@/store/org'
// Register chart.js
import { Chart, registerables } from 'chart.js'
import { useWindowSize } from '@vueuse/core'
import { useCompanyStore } from '@/store/company'
import { useRoute } from 'vue-router'
import { useLocale } from '@/composables/useLocale'
import { LOCALES } from '@/i18n'

Chart.register(...registerables)

const route = useRoute()
const userStore = useUserStore()
const orgStore = useOrgStore()

const { companies } = toRefs(useCompanyStore())
const userCompanyLocale = computed(
  () =>
    companies.value.find(
      ({ id }) => userStore.user?.companyId && id === userStore.user?.companyId,
    )?.locale,
)

const { getLocale, setLocale } = useLocale()

watch(
  () => [orgStore.org?.locale, userStore.user?.locale, userCompanyLocale.value],
  ([orgLocale, userLocale, companyLocale]) => {
    const storedLocale = getLocale()
    setLocale(
      storedLocale ||
        userLocale ||
        companyLocale ||
        orgLocale ||
        (route?.query.language as LOCALES) ||
        LOCALES.EN,
    )
  },
  { immediate: true },
)
watch(
  () => userStore.user?.orgId,
  () => {
    useAppStore().fetchGlobalOrgData()
  },
  { immediate: true },
)

const { width } = useWindowSize()
const isMobileView = computed(() => width.value < 960)
const isSidebarOpen = ref(!isMobileView.value)
</script>

<style lang="scss" scoped>
.LayoutDashboard__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.25rem;
  margin-top: $topbar-height-sm;

  @include md {
    padding: 1.75rem;
    margin-top: unset;
  }
}
</style>

<style lang="scss">
.LayoutDashboard__main {
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;

  @include md {
    margin-left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
  }
}
</style>
