import { useI18n } from 'vue-i18n'
import { locationService } from '@/apiClient/services'
import { createPropertyMap } from '@/utils'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'

export const useLocationStore = defineStore('location', () => {
  const { t } = useI18n()
  const crud = useSocketsCrudFactory(locationService, 'location')

  const locationTranslated = computed(() =>
    crud.list.value
      .map((item) => ({ ...item, name: t(item.name) }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  )

  /**
   * Options for UIInputSelect
   */
  const locationOptions = computed(() =>
    locationTranslated.value.map(({ id, name }) => ({
      title: name,
      value: id,
    })),
  )

  /**
   * Object with { locationId: locationName }
   */
  const locationsIdMap = computed(() =>
    createPropertyMap(locationTranslated.value, 'id', 'name'),
  )

  // Fetch when store is initialised
  if (!crud.isInitialised.value) {
    crud.fetch()
  }

  return {
    locations: locationTranslated,
    locationOptions,
    locationsIdMap,
    ...crud,
  }
})
