import { defineStore, storeToRefs } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { taskService } from '../../apiClient/services'
import { computed } from 'vue'
import { useTaskParticipantStore } from '../taskParticipant'
import { createObjectsArrayMap } from '@/utils'

export const useTaskStore = defineStore('task', () => {
  const socketService = useSocketsCrudFactory(taskService, 'task')

  const taskParticipantStore = useTaskParticipantStore()
  const { taskParticipantsByTaskId } = storeToRefs(taskParticipantStore)

  /**
   * Map of tasks, indexed by resource ID
   */
  const taskByResourceIdMap = computed(() =>
    createObjectsArrayMap(tasks.value, 'resourceId'),
  )

  /**
   * Map of tasks, indexed by resource name
   */
  const tasksByResourceNameMap = computed(() =>
    createObjectsArrayMap(tasks.value, 'resourceName'),
  )

  const tasks = computed(() =>
    socketService.list.value.map((task) => ({
      ...task,
      _taskParticipants: taskParticipantsByTaskId.value[task.id] || [],
    })),
  )

  return {
    ...socketService,
    tasks,
    taskByResourceIdMap,
    tasksByResourceNameMap,
  }
})
