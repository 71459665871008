import { defineStore } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgPeerCompareItemService } from '../../apiClient/services'
import { computed } from 'vue'
import { createObjectsArrayMap, createObjectsMap } from '@/utils'

export const useEsgPeerCompareItem = defineStore('esgPeerCompareItem', () => {
  const socketService = useSocketsCrudFactory(
    esgPeerCompareItemService,
    'esgPeerCompareItem',
  )

  const peerCompareItemMap = computed(() =>
    createObjectsMap(socketService.list.value, 'id'),
  )

  const peerCompareItemsByPeerCompareId = computed(() => {
    return createObjectsArrayMap(socketService.list.value, 'esgPeerCompareId')
  })

  return {
    ...socketService,
    peerCompareItemMap,
    peerCompareItemsByPeerCompareId,
  }
})
