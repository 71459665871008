import { defineStore, storeToRefs } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgProjectRequirementGroupService } from '@/apiClient/services'
import { computed } from 'vue'
import { useEsgRequirementGroupStore } from '../esgRequirementGroup'
import { useTaskStore } from '../task'
import { useEsgProjectAnswerStore } from '../esgProjectAnswer'
import { createObjectsArrayMap, createObjectsMap } from '@/utils'
import {
  ANSWER_STATUS,
  IEsgProjectAnswer,
  IEsgProjectRequirement,
  IEsgRequirement,
  IEsgRequirementAnswerConfig,
} from '@/apiClient/types/esg'
import { useEsgRequirementStore } from '../esgRequirement/index'
import { computeField } from '@/views/pages/esg/Project/AnswersDrawer/components/AnswerField/utils'

export const useEsgProjectRequirementGroupStore = defineStore(
  'esgProjectRequirementGroup',
  () => {
    const socketService = useSocketsCrudFactory(
      esgProjectRequirementGroupService,
      'esgProjectRequirementGroup',
    )

    const { tasks } = storeToRefs(useTaskStore())
    const { esgRequirementGroupsMap } = storeToRefs(
      useEsgRequirementGroupStore(),
    )
    const { esgRequirementByGroupIdMap } = storeToRefs(useEsgRequirementStore())
    const { esgProjectAnswers } = storeToRefs(useEsgProjectAnswerStore())

    /**
     * Map of ESG Project Requirement Groups, indexed by ESG Project ID
     * @type {ComputedRef<Record<string, EsgProjectRequirementGroup[]>>}
     */
    const esgProjectRequirementGroupsByProjectIdMap = computed(() =>
      createObjectsArrayMap(esgProjectRequirementGroups.value, 'esgProjectId'),
    )

    const esgProjectRequirementGroupTasks = computed(() =>
      createObjectsMap(
        tasks.value.filter(
          (task) => task.resourceName === 'esgProjectRequirementGroup',
        ),
        'resourceId',
      ),
    )

    const { esgRequirementByResourceId } = storeToRefs(useEsgRequirementStore())

    const projectAnswersMap = computed<
      Record<number, Record<number, IEsgProjectAnswer[]>>
    >(() =>
      Object.entries(
        createObjectsArrayMap(esgProjectAnswers.value, 'esgProjectId'),
      ).reduce(
        (accum, [esgProjectId, projectAnswers]) => ({
          ...accum,
          [esgProjectId]: createObjectsArrayMap(
            projectAnswers,
            'esgRequirementId',
          ),
        }),
        {},
      ),
    )

    const getProjectAnswers = (
      requirement: IEsgRequirement,
      esgProjectId: number,
    ) => {
      const projectAnswersByRequirementId =
        projectAnswersMap.value[esgProjectId]

      if (!projectAnswersByRequirementId) return []

      return [
        ...(projectAnswersByRequirementId[requirement.id] || []),
        ...(requirement?._subRequirements?.flatMap(
          (subRequirement) =>
            projectAnswersByRequirementId[subRequirement.id] || [],
        ) || []),
      ]
    }

    const getAllConfigs = (
      configs: IEsgRequirementAnswerConfig[],
    ): IEsgRequirementAnswerConfig[] =>
      configs.flatMap((answerConfig) =>
        answerConfig.children
          ? [answerConfig, ...getAllConfigs(answerConfig.children)]
          : [answerConfig],
      )

    const getRequirementTotalFields = (
      requirement: IEsgRequirement,
      esgProjectId: number,
    ) =>
      [
        ...getAllConfigs(requirement.answerConfig || []),
        ...(
          requirement?._subRequirements?.flatMap(
            (subRequirement) => subRequirement.answerConfig,
          ) || []
        ).flatMap((answerConfig) => [
          answerConfig,
          ...getAllConfigs(answerConfig?.children || []).map((config) => ({
            ...config,
            parentAnswerConfigId: answerConfig?.id,
          })),
        ]),
      ].filter((answerConfig) => {
        return answerConfig
        // TODO: reenable isDisabled calculation

        // &&
        // typeof answerConfig.isDisabled === 'string' &&
        // !eval(
        //   [
        //     ...answerConfig.isDisabled.matchAll(
        //       /\{\{([\w-]*?)(\[([0-9]*?)\])?\}\}/g,
        //     ),
        //   ].reduce(
        //     (acc, [match, requirementResourceId, answerConfigIndex]) => {
        //       if (requirementResourceId === 'parentAnswerConfig') {
        //         const answers = requirement?.id
        //           ? getProjectAnswers(requirement, esgProjectId)
        //           : []

        //         const answer = answers.find(
        //           ({ answerConfigId }) =>
        //             answerConfigId === answerConfig.parentAnswerConfigId,
        //         )

        //         return acc.replaceAll(
        //           match,
        //           answer?.valueArr[0].value || 'false',
        //         )
        //       }

        //       const variableRequirement =
        //         esgRequirementByResourceId.value[requirementResourceId]

        //       const answers = variableRequirement?.id
        //         ? getProjectAnswers(variableRequirement, esgProjectId)
        //         : []

        //       return acc.replaceAll(
        //         match,
        //         answers[Number(answerConfigIndex)]?.valueArr[0].value ||
        //           'false',
        //       )
        //     },
        //     answerConfig.isDisabled,
        //   ),
        // ))
        // ||
        // !answerConfig?.isDisabled
      })

    function flattenObject(obj, parent = '', res = {}) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const propName = parent ? parent + '.' + key : key

          if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            flattenObject(obj[key], propName, res)
          } else if (Array.isArray(obj[key])) {
            for (let i = 0; i < obj[key].length; i++) {
              const arrayProp = propName + '.' + i

              if (typeof obj[key][i] === 'object' && obj[key][i] !== null) {
                flattenObject(obj[key][i], arrayProp, res)
              } else {
                res[arrayProp] = obj[key][i]
              }
            }
          } else {
            res[propName] = obj[key]
          }
        }
      }

      return res
    }

    const esgProjectRequirementGroups = computed(() =>
      socketService.list.value.map((group) => {
        const requirementGroup =
          esgRequirementGroupsMap.value[group.esgRequirementGroupId]

        const computeProgress = (requirement: IEsgRequirement) => {
          const excludedConfigsIds = (
            getRequirementTotalFields(requirement, group.esgProjectId) || []
          )
            ?.filter(
              (config) => config && (config.isDisabled || config.isHidden),
            )
            .map(({ id }) => id)

          const projectAnswers = getProjectAnswers(
            requirement,
            group.esgProjectId,
          )

          const getValues = (answerConfigId: string) => {
            // If values is an array
            if (
              Array.isArray(projectAnswers[0]?.value?.[answerConfigId]?.value)
            ) {
              return projectAnswers[0]?.value?.[answerConfigId]?.value
            }

            return Object.entries(
              flattenObject(projectAnswers[0]?.value?.[answerConfigId] || {}),
            )
              .filter(
                ([key]) =>
                  key.endsWith('value') &&
                  !excludedConfigsIds.some((id) => key.includes(id)),
              )
              .map(([_, value]) => value)
          }

          // Compute 'isHidden' and merge it with 'isOptional
          const mandatoryAnswerConfigs = (requirement.answerConfig || []).map(
            (config) => ({
              ...config,
              isOptional:
                config.isOptional ||
                (config.isHidden
                  ? computeField(
                      config.isHidden,
                      esgRequirementByResourceId.value,
                      projectAnswers[0]?.value,
                    )
                  : false),
            }),
          )

          const countTotal = mandatoryAnswerConfigs.filter(
            ({ isOptional }) => !isOptional,
          ).length

          const requirementIsSkipped = projectAnswers.some(
            ({ status }) => status === ANSWER_STATUS.SKIPPED,
          )

          const countCompleted = requirementIsSkipped
            ? 0
            : mandatoryAnswerConfigs.filter(
                ({ id, isOptional }) =>
                  !isOptional &&
                  getValues(id).length &&
                  getValues(id).every(
                    (value) => value != undefined && value !== '',
                  ),
              ).length

          return {
            countTotal,
            countCompleted,
            countSkipped:
              projectAnswers[0]?.status === 'SKIPPED' ? countTotal : 0,
          }
        }

        const requirements: IEsgProjectRequirement[] = (
          esgRequirementByGroupIdMap.value[group.esgRequirementGroupId] || []
        ).map((requirement) => {
          const subRequirements =
            requirement._subRequirements?.map((subRequirement) => ({
              id: subRequirement.id,
              name: subRequirement.name,
              answerConfig: subRequirement.answerConfig,
              esgRequirementGroupId: subRequirement.esgRequirementGroupId,
              resourceId: subRequirement.resourceId,
              esgProjectId: group.esgProjectId,
              _projectAnswers: getProjectAnswers(
                subRequirement,
                group.esgProjectId,
              ),
              _progress: computeProgress(subRequirement),
            })) || []

          const _progress = subRequirements.reduce((accum, { _progress }) => {
            return {
              countTotal: accum.countTotal + _progress.countTotal,
              countCompleted: accum.countCompleted + _progress.countCompleted,
              countSkipped: accum.countSkipped + _progress.countSkipped,
            }
          }, computeProgress(requirement))

          return {
            ...requirement,
            esgProjectId: group.esgProjectId,
            _projectAnswers: getProjectAnswers(requirement, group.esgProjectId),
            _subRequirements: subRequirements,
            _progress,
            _dependsOn: requirement?._dependsOn?.map(
              (dependsOnRequirement) => ({
                ...dependsOnRequirement,
                _projectAnswers: getProjectAnswers(
                  dependsOnRequirement,
                  group.esgProjectId,
                ),
              }),
            ),
          }
        })

        return {
          ...group,
          _requirementGroup: {
            ...requirementGroup,
          },
          _progress: {
            countTotal:
              requirements.filter(({ _progress }) => _progress.countTotal)
                .length || 0,
            countCompleted: requirements.filter(
              ({ _progress }) =>
                _progress.countTotal &&
                _progress.countCompleted === _progress.countTotal,
            ).length,
            countSkipped: requirements.filter(
              ({ _progress }) => _progress.countSkipped,
            ).length,
          },
          _requirements: requirements,
          _task: esgProjectRequirementGroupTasks.value[group.id],
          // _requirementGroup: {
          // ...(requirementGroup || {}),
          // _requirements: (requirementGroup?._requirements || []).map(
          //   (requirement) => {
          //     const answers = _projectAnswersMap[requirement.id] || []
          //     return {
          //       ...requirement,
          //       _subRequirements: requirement._subRequirements.map(
          //         (subRequirement) => {
          //           const subAnswers =
          //             _projectAnswersMap?.[subRequirement.id] || []
          //           const _progress = {
          //             countCompleted: subAnswers.filter(({ valueArr }) =>
          //               valueArr.every(({ value }) => value != undefined && value !== ''),
          //             ).length,
          //             countTotal: subRequirement.answerConfig?.length || 0,
          //           }
          //           subrequirementProgressItems.push(_progress)

          //           return {
          //             ...subRequirement,
          //             _projectAnswers:
          //               _projectAnswersMap?.[subRequirement.id] || [],
          //             _progress,
          //           }
          //         },
          //       ),
          //       _projectAnswers: answers,
          //       _progress: {
          //         // countCompleted:
          //         //   answers.filter(({ valueArr }) =>
          //         //     valueArr.every(({ value }) => value != undefined && value !== ''),
          //         //   ).length +
          //         //   subrequirementProgressItems.reduce(
          //         //     (accum, item) => accum + item.countCompleted,
          //         //     0,
          //         //   ),
          //         // countTotal:
          //         //   (requirement.answerConfig?.length || 0) +
          //         //   subrequirementProgressItems.reduce(
          //         //     (accum, item) => accum + item.countTotal,
          //         //     0,
          //         //   ),
          //         countCompleted: 69,
          //         countTotal: 420,
          //       },
          //     }
          //   },
          // ),
          // },
        }
      }),
    )

    const esgProjectRequirementGroupsByProjectId = computed(() =>
      createObjectsArrayMap(esgProjectRequirementGroups.value, 'esgProjectId'),
    )

    const esgProjectRequirementGroupByEsgRequirementGroupId = computed(() =>
      createObjectsArrayMap(
        esgProjectRequirementGroups.value,
        'esgRequirementGroupId',
      ),
    )

    return {
      ...socketService,
      esgProjectRequirementGroups,
      esgProjectRequirementGroupsByProjectId,
      esgProjectRequirementGroupsByProjectIdMap,
      esgProjectRequirementGroupByEsgRequirementGroupId,
    }
  },
)