import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import lt from './locales/lt.json'
import { translationService } from '@/apiClient/services'
import { ITranslation } from '@/apiClient/types/translation'
import { useFeatureFlagStore } from '@/store/featureFlag'
import { FeatureFlag } from '@/apiClient/types/featureFlag'

export type MessageSchema = typeof en
export type MessagesKeys = keyof MessageSchema

export enum LOCALES {
  EN = 'en',
  LT = 'lt',
}

let translations = [] as ITranslation[]

export const i18n = createI18n<[MessageSchema], LOCALES>({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlMessage: false,
  messages: {
    en,
    lt,
  },
  missing: async (locale: string, key: string) => {
    if (!useFeatureFlagStore().hasFeature(FeatureFlag.translationAutoAdd))
      return
    // if 'en' translation is missing it means that the record doesn't exist in the translations table
    if (locale === 'en' && key) {
      translationService.create({
        key,
        en: key,
      })
    }
  },
})

const formatTranslations = (translations: ITranslation[]) => {
  const formattedTranslations: Record<string, Record<string, string>> = {
    en: {},
    lt: {},
  }

  translations.forEach((translation) => {
    if (translation.en) {
      formattedTranslations.en[translation.key] = translation.en
    }
    if (translation.lt) {
      formattedTranslations.lt[translation.key] = translation.lt
    }
  })

  return formattedTranslations
}

export const updateI18nMessages = (translations: ITranslation[]) => {
  const formattedTranslations = formatTranslations(translations)
  Object.keys(formattedTranslations).forEach((locale) => {
    const messages = i18n.global.getLocaleMessage(locale)
    i18n.global.setLocaleMessage(locale, {
      ...messages,
      ...formattedTranslations[locale],
    })
  })
}

export const initDBTranslations = async () => {
  const { data: _translations } = await translationService.find({
    query: { $limit: 10000 },
  })
  translations = _translations
  updateI18nMessages(translations)
}

/**
 * Find English translation for a Lithuanian string
 * @param lithuanianText The Lithuanian text to translate
 * @param fallback Optional fallback if translation is not found
 * @returns The English translation or fallback value
 */
export const findEnglishTranslation = (
  lithuanianText: string,
  fallback?: string,
): string => {
  try {
    // This implementation depends on how your i18n system is set up
    // Typically you'd need to reverse lookup in your translation files

    // If using vue-i18n with a translation map:
    const litToEngMap = i18n.global.messages.lt

    // Look through Lithuanian translations to find the key for this text
    for (const [key, value] of Object.entries(litToEngMap)) {
      if (value === lithuanianText) {
        // Get the English translation using the found key
        return i18n.global.t(key, { locale: 'en' })
      }
    }

    return fallback || lithuanianText
  } catch (err) {
    console.error(`[findEnglishTranslation]`, err)
    return fallback || lithuanianText
  }
}
