import { defineStore, storeToRefs } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { esgPeerCompareService } from '../../apiClient/services'
import { computed } from 'vue'
import { useEsgPeerCompareItem } from '../esgPeerCompareItem'
import { createObjectsArrayMap } from '@/utils'

export const useEsgPeerCompare = defineStore('esgPeerCompare', () => {
  const socketService = useSocketsCrudFactory(
    esgPeerCompareService,
    'esgPeerCompare',
  )

  //#region ESG Peer Compare items
  const esgPeerCompareItemStore = useEsgPeerCompareItem()
  const { peerCompareItemsByPeerCompareId } = storeToRefs(
    esgPeerCompareItemStore,
  )
  //#endregion

  const peerCompares = computed(() => {
    return socketService.list.value.map((peerCompare) => {
      return {
        ...peerCompare,
        _peerCompareItems:
          peerCompareItemsByPeerCompareId.value[peerCompare.id] || [],
      }
    })
  })

  const esgPeerComparesBySustainAiCompanyId = computed(() =>
    createObjectsArrayMap(peerCompares.value, 'sustainAiCompanyId'),
  )

  return {
    ...socketService,
    peerCompares,
    esgPeerComparesBySustainAiCompanyId,
  }
})
