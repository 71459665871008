import { LOCALES } from '@/i18n'
import { IUIInputDropdownOptionObject } from '@/views/components/ui/dropdowns/types'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export const LOCALE_KEY = 'appSustainalyseLocale'

export const useLocale = () => {
  const i18n = useI18n()

  const allLocaleOptions: IUIInputDropdownOptionObject[] = [
    {
      label: 'EN',
      value: 'en',
      icon: 'flagGB',
      onClick: () => setLocale(LOCALES.EN),
    },
    {
      label: 'LT',
      value: 'lt',
      icon: 'flagLT',
      onClick: () => setLocale(LOCALES.LT),
    },
  ]
  const localeIconMap = {
    en: 'flagGB',
    lt: 'flagLT',
  } as const

  /**
   * Map of locales and their corresponding shortcodes
   */
  const fullLocaleMap = {
    en: 'en-GB',
    lt: 'lt-LT',
  }

  /**
   * Returns the shortcode for the given locale. If not provided then it defaults to the current locale
   */
  const getLocaleShortCode = (locale?: LOCALES) => {
    return fullLocaleMap[locale || getLocale()]
  }

  /**
   * Gets the currently set locale in localStorage. Falls back to i18n locale
   * @returns Current locale
   */
  const getLocale = () => {
    return (localStorage.getItem(LOCALE_KEY) as LOCALES) || currentLocale.value
  }
  /**
   * Returns currently set locale
   */
  const currentLocale = computed(() => i18n.locale.value as LOCALES)
  /**
   * Returns the current locale icon (flag)
   */
  const localeIcon = computed(() => {
    return localeIconMap[currentLocale.value || getLocale() || 'en']
  })

  /**
   * Sets the locale in i18n and localStorage
   * @param locale Locale
   * @example 'en' | 'lt'
   */
  const setLocale = (locale: LOCALES) => {
    i18n.locale.value = locale
    localStorage.setItem(LOCALE_KEY, locale)
  }

  return {
    allLocaleOptions,
    localeIconMap,
    localeIcon,
    getLocaleShortCode,
    getLocale,
    setLocale,
  }
}
