<template>
  <div class="TheTopbar">
    <UIButtonIcon
      class="TheTopbar__menu"
      icon="menu"
      @click="$emit('toggleSidebar')"
    />

    <TheTopbarOrgImpersonation v-if="isImpersonatingOrg" />

    <div v-else class="TheTopbar__org-name">{{ org?.name }}</div>

    <div class="TheTopbar__right-options">
      <!-- <UIButtonIcon
        icon="bellOutline"
      /> -->
      <TheNotifications />
      <LocaleDropdown class="TheTopbar__locale-dropdown" />
      <UserDropdown class="TheTopbar__user-dropdown" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import UIButtonIcon from '../../buttons/UIButtonIcon.vue'
import TheTopbarOrgImpersonation from './TheTopbarOrgImpersonation.vue'
import { useOrgStore } from '@/store/org'
import { useAppStore } from '@/store/app'
import LocaleDropdown from './components/LocaleDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import TheNotifications from './components/TheNotifications.vue'

const { isImpersonatingOrg } = storeToRefs(useAppStore())
const { org } = storeToRefs(useOrgStore())

defineEmits(['toggleSidebar'])
</script>

<style scoped lang="scss">
.TheTopbar {
  position: fixed;
  width: 100%;
  background-color: $color-white;
  padding: 0 1.375rem;
  height: $topbar-height-sm;
  display: flex;
  align-items: center;
  // box-shadow: 1px 1px 4px 0px rgba(54, 74, 99, 0.2);
  z-index: 99;

  @include md {
    padding: 0 1.5rem;
    position: relative;
    height: $topbar-height-md;
  }

  .TheTopbar__menu {
    --size: 1.5rem;
    cursor: pointer;
    margin-right: 1rem;

    @include md {
      display: none;
    }
  }

  .TheTopbar__org-name {
    font-size: 1.25rem;
    color: $color-black;
    font-weight: 700;
    font-family: $font-nunito;

    @include md {
      font-size: 2rem;
    }
  }

  .TheTopbar__right-options {
    margin-left: auto;
    display: flex;
    align-items: center;
    .TheTopbar__locale-dropdown {
      margin-left: 0.75rem;
    }

    .TheTopbar__user-dropdown {
      margin-left: 0.5rem;
    }
  }

  .UIButtonIcon {
    --size: 1.125rem;
  }
}
</style>
