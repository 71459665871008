import { RouteRecordRaw } from 'vue-router'
import { organisationRoutes } from './modules/organisation'
import { settingsRoutes } from './modules/settings'
import { emissionRoutes } from './modules/emissions'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { addRoutePrefix } from '../utils'
import { adminRoutes } from './modules/admin'

export const co2Routes: RouteRecordRaw[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/pages/co2/Dashboard/Index.vue'),
  },
  {
    path: '/validation',
    name: 'Validation',
    component: () => import('@/views/pages/co2/Validation/Index.vue'),
    meta: {
      featureFlag: FeatureFlag.validation,
    },
  },
  {
    path: 'consolidation',
    name: 'Consolidation',
    component: () => import('@/views/pages/co2/Consolidation/Index.vue'),
    children: [
      {
        path: 'consolidation/scope2',
        name: 'ConsolidationScope2',
        component: () =>
          import(
            '@/views/pages/co2/Consolidation/tabs/ConsolidationScope2.vue'
          ),
      },
      {
        path: 'consolidation/scope3',
        name: 'ConsolidationScope3',
        component: () =>
          import(
            '@/views/pages/co2/Consolidation/tabs/ConsolidationScope3.vue'
          ),
      },
    ],
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('@/views/pages/co2/Analytics/Index.vue'),
    redirect: { name: 'AnalyticsTabOverview' },
    children: [
      {
        path: 'overview',
        name: 'AnalyticsTabOverview',
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabOverview.vue'),
      },
      {
        path: 'scope1',
        name: 'AnalyticsTabScope1',
        meta: {
          featureFlag: FeatureFlag.scopeOneAndTwo,
        },
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabScope1.vue'),
      },
      {
        path: 'scope1-agriculture',
        name: 'AnalyticsTabScope1Agriculture',
        meta: {
          featureFlag: FeatureFlag.scopeOneAgriculture,
        },
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabScope1Agriculture.vue'),
      },
      {
        path: 'scope2',
        name: 'AnalyticsTabScope2',
        meta: {
          featureFlag: FeatureFlag.scopeOneAndTwo,
        },
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabScope2.vue'),
      },
      {
        path: 'scope3',
        name: 'AnalyticsTabScope3',
        meta: {
          featureFlag: FeatureFlag.scopeThree,
        },
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabScope3.vue'),
      },
      {
        path: 'emission-intensity',
        name: 'AnalyticsTabEmissionIntensity',
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabEmissionIntensity.vue'),
        meta: {
          title: 'page.analytics.emissionsIntensity',
        },
      } as RouteRecordRaw,
      {
        path: 'energy',
        name: 'AnalyticsTabEnergyConsumption',
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabEnergyConsumption.vue'),
      },
      {
        path: 'energy-intensity',
        name: 'AnalyticsTabEnergyIntensity',
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabEnergyIntensity.vue'),
        meta: {
          title: 'page.analytics.energyIntensity',
        },
      } as RouteRecordRaw,
      {
        path: 'reporting-summary',
        name: 'AnalyticsTabReportingSummary',
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabReportingSummary.vue'),
      },
      {
        path: 'product-lines',
        name: 'AnalyticsTabProductLines',
        component: () =>
          import('@/views/pages/co2/Analytics/tabs/TabProductLines.vue'),
      },
    ],
  },
  ...emissionRoutes,
  ...settingsRoutes,
  ...organisationRoutes,
  ...adminRoutes,
].map((item) => addRoutePrefix('co2')(item))
