<template>
  <div class="UIDrawerOverlay">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue'

onMounted(() => {
  document.documentElement.style.overflow = 'hidden'
})

onBeforeUnmount(() => {
  document.documentElement.style.overflow = 'unset'
})
</script>

<style scoped lang="scss">
.UIDrawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  // $newcolor-secondary-main
  background-color: rgba(54, 74, 99, 0.5);
  z-index: 1999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}
</style>
