import { useUserStore } from '@/store/user'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ITheSidebarMenuGroup } from '../types'
import { UserRoleGroups } from '@/apiClient/types/user'
import { useRoute } from 'vue-router'
import { useFeatureFlagStore } from '@/store/featureFlag'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { ESG_FRAMEWORK } from '@/apiClient/types/esg'

export const useSidebarItems = () => {
  const { t } = useI18n()
  const userStore = useUserStore()
  const featureFlagStore = useFeatureFlagStore()

  const co2MenuItemGroups = computed<ITheSidebarMenuGroup[]>(() => [
    {
      items: [
        // {
        //   label: t('page.dashboard'),
        //   to: { name: 'Dashboard' },
        //   icon: 'dashboard',
        //   isHidden: !userStore.user?.orgId,
        //   children: [
        //     {
        //       label: t('energyConsumption'),
        //       icon: 'lightningBolt',
        //       to: { name: 'EnergyConsumption' },
        //     },
        //   ],
        // },
        {
          label: t('page.analytics'),
          to: { name: 'AnalyticsTabOverview' },
          icon: 'chartLine', //'editOutline',
          isHidden: !userStore.user?.orgId,
        },
        {
          label: t('page.addData'),
          to: { name: 'Emissions' },
          icon: 'editOutline',
          isHidden: !userStore.user?.orgId,
        },
        {
          label: t('page.validation'),
          to: { name: 'Validation' },
          icon: 'alertCircleOutline',
          isHidden: !featureFlagStore.hasFeature(FeatureFlag.validation),
        },
        {
          label: t('page.goalsAndTargets'),
          icon: 'target',
          isComingSoon: true,
        },
        {
          label: t('page.carbonOffsets'),
          icon: 'recycle',
          isComingSoon: true,
        },

        // {
        //   label: t('organisation'),
        //   icon: 'organisation',
        //   isHidden:
        //     userStore.hasRole(UserRoleGroups.USER) || !userStore.user?.orgId,
        //   children: [
        //     {
        //       label: t('usersList'),
        //       to: { name: 'OrganisationUsers' },
        //     },
        //     {
        //       label: t('userInvites'),
        //       to: { name: 'OrganisationUserInvites' },
        //     },
        //   ],
        // },
        {
          label: t('adminTools'),
          icon: 'lock',
          isHidden: userStore.hasRole(UserRoleGroups.ORGADMIN_AND_UNDER),
          children: [
            {
              label: t('allOrganisations'),
              to: { name: 'Organisations' },
            },
            {
              label: t('allUsers'),
              to: { name: 'Users' },
            },
            {
              label: t('organisationInvites'),
              to: { name: 'OrganisationInvites' },
            },
            {
              label: t('featureFlags'),
              to: { name: 'FeatureFlags' },
            },
            {
              label: t('actionLog'),
              to: { name: 'ActionLogs' },
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          label: 'POC',
          icon: 'dev',
          isHidden: userStore.hasRole(UserRoleGroups.ORGADMIN_AND_UNDER),
          children: [
            {
              label: 'Buttons',
              to: { name: 'POCButtons' },
            },
            {
              label: 'Typography',
              to: { name: 'POCTypography' },
            },
            {
              label: 'Icons',
              to: { name: 'POCIcons' },
            },
            {
              label: 'Inputs',
              to: { name: 'POCInputs' },
            },
            {
              label: 'Dropdowns',
              to: { name: 'POCDropdowns' },
            },
            {
              label: 'Blocks',
              to: { name: 'POCBlocks' },
            },
            {
              label: 'Form Wizard',
              to: { name: 'POCFormWizard' },
            },
            {
              label: 'Grid',
              to: { name: 'POCGrid' },
            },
            {
              label: 'Modals',
              to: { name: 'POCModals' },
            },
            {
              label: 'Drawer',
              to: { name: 'POCDrawer' },
            },
            {
              label: 'Tabs',
              to: { name: 'POCTabs' },
            },
            {
              label: 'Table',
              to: { name: 'POCTable' },
            },
            {
              label: 'Section',
              to: { name: 'POCSection' },
            },
            {
              label: 'Users',
              to: { name: 'POCUsers' },
            },
            {
              label: 'Api Explorer',
              to: { name: 'POCApiExplorer' },
            },
            {
              label: 'Playground',
              to: { name: 'POCPlayground' },
            },
            {
              label: 'AnswerConfigs',
              to: { name: 'POCAnswerConfigs' },
            },
            {
              label: 'Swagger',
              to: { name: 'POCSwagger' },
            },
            {
              label: 'Notifications',
              to: { name: 'POCNotifications' },
            },
          ],
        },
      ],
    },
  ])

  const esgMenuItemGroups = computed<ITheSidebarMenuGroup[]>(() => [
    {
      items: [
        {
          label: t('sidebar.dashboard'),
          to: { name: 'EsgDashboard' },
          icon: 'dashboard',
        },
        {
          label: t('esg.projects'),
          icon: 'speedometer',
          children: [
            {
              label: ESG_FRAMEWORK.GRI,
              to: { name: 'EsgProjects', params: { framework: 'GRI' } },
            },
            {
              label: ESG_FRAMEWORK.ESRS,
              to: { name: 'EsgProjects', params: { framework: 'ESRS' } },
            },
          ],
        },
        {
          label: t('page.marketInsights'),
          to: { name: 'MarketInsights' },
          icon: 'robotHappyOutline',
          isHidden: !featureFlagStore.hasFeature(FeatureFlag.marketInsights),
        },
        {
          label: t('sidebar.reports'),
          to: { name: 'EsgReports' },
          icon: 'fileChartOutline',
        },
        {
          label: t('page.GAPAnalysis'),
          icon: 'search',
          to: { name: 'GAPAnalysis' },
          isHidden: !featureFlagStore.hasFeature(FeatureFlag.gapAnalysis),
        },
        {
          label: t('page.PeerComparison'),
          icon: 'compareHorizontal',
          to: { name: 'PeerComparison' },
          isHidden: !featureFlagStore.hasFeature(FeatureFlag.peerComparison),
        },
        {
          label: t('sidebar.indicators'),
          to: { name: 'EsgTopics' },
          icon: 'viewGrid',
        },
        {
          label: t('page.euTaxonomy'),
          icon: 'familyTree',
          to: { name: 'EuTaxonomy' },
          isComingSoon: true,
          isHidden: !featureFlagStore.hasFeature(FeatureFlag.euTaxonomy),
        },
        {
          label: t('page.climateRisksAssessment'),
          icon: 'shieldAlertOutline',
          isComingSoon: true,
          to: { name: 'ClimateRisksAssessment' },
          isHidden: !featureFlagStore.hasFeature(
            FeatureFlag.climateRisksAssessment,
          ),
        },
        {
          label: t('page.doubleMateriality'),
          icon: 'webSync',
          isComingSoon: true,
          to: { name: 'DoubleMateriality' },
          isHidden: !featureFlagStore.hasFeature(FeatureFlag.doubleMateriality),
        },
        // {
        //   label: t('GRI'),
        //   icon: 'gri',
        //   to: { name: 'EsgProjects', params: { framework: 'GRI' } },
        // },
        // {
        //   label: t('sidebar.myTeam'),
        //   icon: 'accountGroupOutline',
        //   isHidden:
        //     userStore.hasRole(UserRoleGroups.USER) || !userStore.user?.orgId,
        //   to: { name: 'EsgMyTeam' },
        // },
        {
          label: t('adminTools'),
          icon: 'lock',
          isHidden: userStore.hasRole(UserRoleGroups.ORGADMIN_AND_UNDER),
          children: [
            {
              label: t('allOrganisations'),
              to: { name: 'EsgOrganisations' },
            },
            {
              label: t('allUsers'),
              to: { name: 'EsgUsers' },
            },
            {
              label: t('organisationInvites'),
              to: { name: 'EsgOrganisationInvites' },
            },
            {
              label: t('featureFlags'),
              to: { name: 'EsgFeatureFlags' },
            },
            {
              label: t('actionLog'),
              to: { name: 'EsgActionLogs' },
            },
          ],
        },
      ],
    },
  ])

  const isEsg = computed(() => route.path.includes('/esg'))

  const route = useRoute()
  const menuItemGroups = computed(() =>
    isEsg.value ? esgMenuItemGroups.value : co2MenuItemGroups.value,
  )

  const title = computed(() =>
    isEsg.value ? t('sidebar.esgManagement') : t('sidebar.co2Management'),
  )

  const settingRouteName = computed(() =>
    isEsg.value ? 'EsgSettings' : 'Settings',
  )

  return {
    menuItemGroups,
    title,
    settingRouteName,
    isEsg,
  }
}
