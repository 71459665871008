import {
  IIntensityMetricRecord,
  INTENSITY_METRIC_TYPE,
} from './../../apiClient/types/emissions'
import { useIntensityMetricRecordStore } from './../intensityMetricRecord/index'
import { intensityMetricService } from '@/apiClient/services'
import { IIntensityMetric } from '@/apiClient/types/emissions'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { createObjectsMap } from '@/utils'
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export const useIntensityMetricStore = defineStore('intensityMetric', () => {
  const crud = useSocketsCrudFactory(intensityMetricService, 'intensityMetric')

  const { t } = useI18n()

  const metricRecordStore = useIntensityMetricRecordStore()

  const intensityMetricsWithRecords = computed(() =>
    crud.list.value.map((intensityMetric) => ({
      ...intensityMetric,
      name: t(intensityMetric.name),
      records:
        metricRecordStore.intensityMetricRecordsByMetricId[
          intensityMetric.id
        ] || [],
    })),
  )

  const intensityMetricsMap = computed(() =>
    createObjectsMap(intensityMetricsWithRecords.value, 'id'),
  )

  const intensityMetricsWithRecordsMap = computed<
    Record<number, Record<string, IIntensityMetricRecord>>
  >(() =>
    intensityMetricsWithRecords.value.reduce(
      (accum, metric) => ({
        ...accum,
        [metric.id]: createObjectsMap(metric.records, 'version'),
      }),
      {},
    ),
  )

  const create = async (intensityMetric: IIntensityMetric) => {
    const { id } = await crud.create(intensityMetric)

    if (!intensityMetric.records.length) return

    await metricRecordStore.create(
      intensityMetric.records.map((version) => ({
        ...version,
        intensityMetricId: id,
      })),
    )
  }

  const patch = async (intensityMetric: IIntensityMetric) => {
    if (!intensityMetric.id) {
      throw new Error(
        'Intensity Metric "patch" method is missing property "id"',
      )
    }

    const metricId: number = intensityMetric.id

    await crud.patch(intensityMetric)

    const newVersions = intensityMetric.records.filter(({ id }) => !id)
    const currentVersions = intensityMetric.records.filter(({ id }) => id)

    const addMetricId = (version: IIntensityMetricRecord) => ({
      ...version,
      intensityMetricId: metricId,
    })

    await Promise.all([
      ...(newVersions.length
        ? [metricRecordStore.create(newVersions.map(addMetricId))]
        : []),
      ...(currentVersions.length
        ? [metricRecordStore.patch(currentVersions.map(addMetricId))]
        : []),
    ])
  }

  const intensityMetricTypeOptions = computed(() => [
    {
      title: t('page.intensityMetrics.standard'),
      description: t('page.intensityMetrics.standard.description'),
      value: INTENSITY_METRIC_TYPE.STANDARD,
    },
    {
      title: t('page.intensityMetrics.specific'),
      description: t('page.intensityMetrics.specific.description'),
      value: INTENSITY_METRIC_TYPE.SPECIFIC,
    },
  ])

  return {
    intensityMetrics: intensityMetricsWithRecords,
    intensityMetricTypeOptions,
    intensityMetricsWithRecordsMap,
    intensityMetricsMap,
    ...crud,
    create,
    patch,
  }
})
