import { useI18n } from 'vue-i18n'
import { useLocale } from './useLocale'

export const useFormatters = () => {
  const { getLocaleShortCode } = useLocale()
  const { t } = useI18n()

  /**
   * Formats the given number according to the user locale
   * @param value Number to be formatted
   * @param opts (Optional) Intl.NumberFormatOptions
   * @param {boolean} opts.dynamicMaximumFractionDigits - If true, precision is adapted based on the value's decimal part.
   * @returns Formatted number
   */
  const formatNumber = (
    value: number,
    precision = 2,
    opts?: Intl.NumberFormatOptions & {
      dynamicMaximumFractionDigits?: boolean
    },
  ) => {
    const number =
      (value && precision && parseFloat(value?.toFixed(precision))) ||
      value ||
      0

    const getPrecision = () => {
      if (!opts?.dynamicMaximumFractionDigits) return precision

      const fractionPart = String(value).split('.')[1]
      if (!fractionPart) return precision

      const dynamicPrecision = Array.from(fractionPart).findIndex(
        (char) => char !== '0',
      )

      return dynamicPrecision + precision
    }

    return new Intl.NumberFormat(getLocaleShortCode(), {
      ...opts,
      maximumFractionDigits: getPrecision(),
    }).format(number)
  }

  /**
   * Parse a localized number to a float.
   * @param {string} stringNumber - the localized number
   */
  const parseNumber = (stringNumber: string) => {
    const locale = getLocaleShortCode()
    const thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, '')
    const decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, '')

    return parseFloat(
      stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.'),
    )
  }

  /**
   * Formats the given date according to the user locale
   * @param value Date to be formatted
   * @param opts (Optional) Intl.DateTimeFormatOptions
   * @returns Formatted date
   */
  const formatDate = (
    date: Date | string,
    opts?: Intl.DateTimeFormatOptions,
  ) => {
    const realDate = typeof date === 'string' ? new Date(date) : date

    return new Intl.DateTimeFormat(getLocaleShortCode(), opts).format(realDate)
  }

  /**
   * Calculates the time passed since a given timestamp and returns a human-readable string.
   *
   * @param {Date} timestamp1 - The initial timestamp to compare against the current time.
   * @param {number} [accuracyLevel=3] - The level of detail to include in the output (e.g., 1 for just days, 2 for days and hours, etc.).
   * @returns {string} A string representing the time passed since the given timestamp, formatted as "X days Y hours Z minutes W seconds ago".
   */
  const units: Record<
    string,
    { day: string; hour: string; minute: string; second: string }
  > = {
    'en-EN': { day: 'd', hour: 'h', minute: 'min', second: 's' },
    'lt-LT': { day: 'd.', hour: 'val.', minute: 'min.', second: 's' },
  }

  const formatTimePassed = (timestamp1: Date, accuracyLevel = 3) => {
    const msPerSecond = 1000
    const msPerMinute = msPerSecond * 60
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24

    const unit = units[getLocaleShortCode()] || units['en-EN']

    let elapsed = Math.abs(new Date().getTime() - timestamp1.getTime())

    const days = Math.floor(elapsed / msPerDay)
    elapsed -= days * msPerDay

    const hours = Math.floor(elapsed / msPerHour)
    elapsed -= hours * msPerHour

    const minutes = Math.floor(elapsed / msPerMinute)
    elapsed -= minutes * msPerMinute

    const seconds = Math.floor(elapsed / msPerSecond)

    const result = []
    if (days > 0) {
      result.push(`${days} ${unit.day}`)
    }
    if (hours > 0) {
      result.push(`${hours} ${unit.hour}`)
    }
    if (minutes > 0) {
      result.push(`${minutes} ${unit.minute}`)
    }
    if (seconds > 0 || !result.length) {
      result.push(`${seconds} ${unit.second}`)
    }

    return t('timePassed', [result.slice(0, accuracyLevel).join(' ')])
  }

  return {
    formatNumber,
    parseNumber,
    formatDate,
    formatTimePassed,
  }
}
