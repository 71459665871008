import { esgDynamicOptionService } from '@/apiClient/services'
import { defineStore } from 'pinia'
import { useSocketsCrudFactory } from '@/composables/useSocketsCrudFactory'
import { computed } from 'vue'
import { createObjectsArrayMap } from '@/utils'

export const useEsgDynamicOption = defineStore('esgDynamicOption', () => {
  const crud = useSocketsCrudFactory(
    esgDynamicOptionService,
    'esgDynamicOption',
  )

  const esgDynamicOptionsByType = computed(() =>
    createObjectsArrayMap(crud.list.value, 'type'),
  )

  return {
    ...crud,
    esgDynamicOptionsByType,
  }
})
