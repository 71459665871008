export const getDatesDifference = (date1: Date, date2: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const diffTime = date2.getTime() - date1.getTime()

  return Math.round(diffTime / oneDay)
}

export const convertToDateLocalString = (date: Date) => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

/**
 * Returns the time passed between 2 timestamps
 * @param timestamp1
 * @param timestamp2
 * @returns
 */
export const timePassed =
  (accuracyLevel = 3) =>
  (timestamp1: Date, timestamp2?: Date) => {
    const msPerSecond = 1000
    const msPerMinute = msPerSecond * 60
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24

    let elapsed = Math.abs(
      (timestamp2 || new Date()).getTime() - timestamp1.getTime(),
    )

    const days = Math.floor(elapsed / msPerDay)
    elapsed -= days * msPerDay

    const hours = Math.floor(elapsed / msPerHour)
    elapsed -= hours * msPerHour

    const minutes = Math.floor(elapsed / msPerMinute)
    elapsed -= minutes * msPerMinute

    const seconds = Math.floor(elapsed / msPerSecond)

    const result = []
    if (days > 0) {
      result.push(`${days} day${days !== 1 ? 's' : ''}`)
    }
    if (hours > 0) {
      result.push(`${hours} hour${hours !== 1 ? 's' : ''}`)
    }
    if (minutes > 0) {
      result.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`)
    }
    if (seconds > 0 || !result.length) {
      result.push(`${seconds} second${seconds !== 1 ? 's' : ''}`)
    }

    return `${result.slice(0, accuracyLevel).join(' ')} ago`
  }
