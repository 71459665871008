<template>
  <UIDrawer class="NotificationDrawer" @close="emit('close')">
    <template #title>{{ notification.label }}</template>

    <template #content>
      <div v-html="notification.content" />
    </template>
    <template #actions v-if="cta">
      <UIButton :to="cta" @click.prevent="emit('close')">
        {{ t(notification.ctaLabel || '') }}
      </UIButton>
    </template>
  </UIDrawer>
</template>

<script lang="ts" setup>
import UIDrawer from '@/views/components/ui/drawers/UIDrawer.vue'
import UIButton from '@/views/components/ui/buttons/UIButton.vue'
import { PropType, computed } from 'vue'
import { INotification } from '@/apiClient/types/notification'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { notification } = defineProps({
  notification: {
    type: Object as PropType<Partial<INotification>>,
    required: true,
  },
})
const emit = defineEmits(['close'])

const cta = computed(() => {
  if (!notification?.ctaPath) return null

  return notification.ctaPath.startsWith('/')
    ? notification.ctaPath
    : `/${notification.ctaPath}`
})
</script>

<style scoped lang="scss">
.NotificationDrawer {
}
</style>
