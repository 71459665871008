export const apiOrigin =
  import.meta.env?.VITE_API_ORIGIN ||
  process.env.VITE_API_ORIGIN ||
  process.env.VUE_APP_API_ORIGIN ||
  'http://localhost:8091'
export const apiBase = import.meta.env?.VITE_API_BASE || '/v1'
export const apiPort = import.meta.env?.VITE_API_PORT
export const jwtName = 'susjwt'
export const isProduction = process.env.NODE_ENV === 'production'
export const debugLevel = import.meta.env?.VITE_LOG_LEVEL

const propertyId = import.meta.env?.VITE_TAWKTO_PROPERTY_ID
const widgetId = import.meta.env?.VITE_TAWKTO_WIDGET_ID

export const isTawkEnabled = propertyId && widgetId
