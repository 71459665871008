import { i18n, initDBTranslations } from './i18n/index'
import App from './App.vue'
import { createApp } from 'vue'
import { loadFonts } from './plugins/webfontloader'
import router from '@/router'
import store from '@/store'
import * as Sentry from '@sentry/vue'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3'
import { isTawkEnabled } from './config'
import { useUserStore } from './store/user'
import { storeToRefs } from 'pinia'
import { useOrgStore } from './store/org'

loadFonts()
const app = createApp(App).use(router).use(store).use(i18n)

initDBTranslations()

// Error handler
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.1,
  beforeSend(event) {
    const { user } = storeToRefs(useUserStore())
    const { org } = storeToRefs(useOrgStore())
    if (user.value) {
      event.user = user.value
      // Add organization info to the context
      event.contexts = {
        ...event.contexts,
        organization: org.value,
      }
    }

    return event
  },
  ignoreErrors: [
    /Failed to fetch dynamically imported module/,
    /Importing a module script failed/,
    /Unable to preload CSS/,
  ],
})

const propertyId = import.meta.env.VITE_TAWKTO_PROPERTY_ID
const widgetId = import.meta.env.VITE_TAWKTO_WIDGET_ID
if (isTawkEnabled) {
  app.use(TawkMessengerVue, {
    propertyId,
    widgetId,
  })
}

app.mount('#app')
