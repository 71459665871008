import { ref, Ref, unref, reactive } from 'vue'

export const useModal = <T>(data?: Ref<T> | T) => {
  const isOpen = ref(false)
  const modalData = ref(unref(data)) as Ref<T | undefined>

  const open = (data?: Ref<T> | T) => {
    if (!(data instanceof PointerEvent) && data) {
      modalData.value = unref(data)
    }
    isOpen.value = true
  }
  const close = () => {
    isOpen.value = false
    modalData.value = undefined
  }

  return reactive({
    isOpen,
    modalData,
    open,
    close,
  })
}
