import LayoutDashboard from '@/views/layouts/Dashboard.vue'
import LayoutAuth from '@/views/layouts/Auth.vue'
import { RouteRecordRaw } from 'vue-router'
import { co2Routes } from './co2/routes'
import { esgRoutes } from './esg/routes'
import { FeatureFlag } from '@/apiClient/types/featureFlag'
import { pocRoutes } from './global/poc'

const routes = [
  // Misc pages
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/pages/Logout/Index.vue'),
  },

  // Auth pages
  {
    path: '',
    component: LayoutAuth,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {
          isPublic: true,
        },
        component: () => import('@/views/pages/Login/Index.vue'),
      },
      {
        path: '/login/forgot',
        name: 'LoginForgot',
        meta: {
          isPublic: true,
        },
        component: () => import('@/views/pages/LoginForgot/Index.vue'),
      },
      {
        path: '/login/reset',
        name: 'LoginReset',
        meta: {
          isPublic: true,
        },
        component: () => import('@/views/pages/LoginReset/Index.vue'),
      },
      {
        path: '/register',
        name: 'Register',
        meta: {
          isPublic: true,
        },
        component: () => import('@/views/pages/Register/Index.vue'),
      },
      {
        path: '/register/finalise',
        name: 'RegisterFinalisation',
        meta: {
          isPublic: false,
        },
        component: () => import('@/views/pages/RegisterFinalisation/Index.vue'),
      },
    ],
  },

  // Dashboard
  {
    name: 'LayoutDashboard',
    component: LayoutDashboard,
    children: [
      {
        path: '/co2',
        name: 'CO2',
        redirect: {
          name: 'Emissions',
        },
        meta: {
          featureFlag: FeatureFlag.ghgPlatform,
        },
        children: co2Routes,
      },
      {
        path: '/esg',
        name: 'ESG',
        meta: {
          featureFlag: FeatureFlag.esgPlatform,
        },
        redirect: {
          name: 'EsgDashboard',
        },
        children: esgRoutes,
      },
      {
        path: '/notifications/:id?',
        name: 'Notifications',
        component: () => import('@/views/pages/Notifications/Index.vue'),
      },
      ...pocRoutes,
    ],
  },
] as RouteRecordRaw[]

export default routes
