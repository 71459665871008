<template>
  <div class="NotificationItem">
    <UIIcon
      class="NotificationItem__icon"
      :name="getNotificationIcon(notification)"
    />

    <div class="NotificationItem__content">
      <div
        :class="[
          'NotificationItem__content-label',
          {
            'NotificationItem__content-label--unseen': !notification.seenAt,
          },
        ]"
      >
        {{ notification.label }}
      </div>
      <div class="NotificationItem__content-timestamp">
        {{ timestamp }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { INotification } from '@/apiClient/types/notification'
import UIIcon from '../../../icons/UIIcon.vue'
import { IIcons } from '../../../icons/types'
import { timePassed } from '@/utils/dates'
import { computed } from 'vue'

const props = defineProps<{
  notification: INotification
}>()

const ICON_BY_TYPE: Record<INotification['type'], IIcons> = {
  system: 'robotHappyOutline',
  app: 'robotHappy',
  user: 'accountOutline',
}

const getNotificationIcon = (notification: INotification) => {
  return ICON_BY_TYPE[notification.type] || 'robotHappyOutline'
}
const timePassedWithAccuracy = timePassed(1)
const timestamp = computed(() =>
  timePassedWithAccuracy(new Date(props.notification.createdAt)),
)
</script>

<style scoped lang="scss">
.NotificationItem {
  display: flex;
  padding: 0.5rem 1.25rem;
  cursor: pointer;

  &:hover {
    background-color: $newcolor-grey-50;
  }

  .NotificationItem__icon {
    --size: 1.25rem;
    margin-right: 0.5rem;
  }

  .NotificationItem__content-label {
    font-size: 0.75rem;
    color: $color-primary-dark;

    overflow: hidden;
    text-overflow: ellipsis;

    width: 12rem;

    &--unseen {
      font-weight: 700;
    }
  }

  .NotificationItem__content-timestamp {
    font-size: 0.5rem;
    color: $newcolor-grey-500;
  }
}
</style>
