<template>
  <UIDropdown class="TheNotifications" :items="notificationsToShow">
    <template #menu-before="{ hide }">
      <div class="TheNotifications__menu-before">
        <NotificationLinkButton :to="{ name: 'Notifications' }" @click="() => hide()">
          Notifications
        </NotificationLinkButton>
        <NotificationLinkButton
          :disabled="!notificationsUnseenCount"
          @click="setAllNotificationsAsSeen(hide)"
        >
          Mark All as Read
        </NotificationLinkButton>
      </div>
    </template>

    <template #menu-list="{ options, hide }">
      <div v-if="isLoading" class="Notification__loader">
        <UILoader />
      </div>
      <div v-else-if="!notificationsToShow.length" class="Notification__empty">
        You have no notifications.
      </div>

      <UILazyRender :items="options" class="TheNotifications__content" :portionCount="6">
        <template #item="{ item }">
          <NotificationItem
            v-if="item"
            :notification="item"
            @click="
              () => {
                notificationDrawerModal.open(item)
                notificationStore.setAsSeen(item)
                hide()
              }
            "
          />
        </template>
      </UILazyRender>
    </template>

    <template #menu-after="{ hide }">
      <NotificationLinkButton :to="{ name: 'Notifications' }" @click="hide">
        View all
      </NotificationLinkButton>
    </template>

    <UIButtonIcon
      :class="[
        'TheNotifications__icon',
        { 'TheNotifications__icon--has-unseen': notificationsUnseenCount > 0 },
      ]"
      icon="bellOutline"
    />
  </UIDropdown>

  <NotificationDrawer
    v-if="notificationDrawerModal.modalData"
    :isOpen="notificationDrawerModal.isOpen"
    @close="notificationDrawerModal.close()"
    :notification="notificationDrawerModal.modalData"
    size="sm"
  />
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useUserStore } from '@/store/user'
import { useNotificationStore } from '@/store/notification'
import { storeToRefs } from 'pinia'
import UIDropdown from '@/views/components/ui/dropdowns/UIDropdown.vue'
import UIButtonIcon from '../../../buttons/UIButtonIcon.vue'
import UILazyRender from '@/views/components/ui/lazyRender/UILazyRender.vue'
import { useToast } from '@/store/toast'
import NotificationItem from './NotificationItem.vue'
import NotificationLinkButton from './NotificationLinkButton.vue'
import { useModal } from '@/composables/useModal'
import { INotification } from '@/apiClient/types/notification'
import UILoader from '@/views/components/ui/loaders/UILoader.vue'
import NotificationDrawer from '@/views/pages/Notifications/NotificationDrawer.vue'

const NOTIFICATIONS_TO_SHOW_COUNT = 15

const notificationStore = useNotificationStore()
const { notificationsUnseen, notificationsUnseenCount, notificationsSeen, isLoading } =
  storeToRefs(notificationStore)

const { onSuccess, onError } = useToast()

const setAllNotificationsAsSeen = async (hide: () => void) => {
  if (!notificationsUnseenCount.value) return

  notificationStore
    .setAllAsSeen()
    .then(() => {
      onSuccess('All notifications set to seen')
    })
    .catch(() => onError('Could not set all notifications as seen'))

  hide()
}

const notificationsToShow = computed(() => {
  if (notificationsUnseenCount.value >= NOTIFICATIONS_TO_SHOW_COUNT)
    return notificationsUnseen.value

  return [
    ...notificationsUnseen.value,
    ...notificationsSeen.value.slice(
      0,
      NOTIFICATIONS_TO_SHOW_COUNT - notificationsUnseenCount.value,
    ),
  ]
})

const notificationDrawerModal = useModal<Partial<INotification>>({})

watch(
  () => useUserStore().user,
  () => {
    useNotificationStore().fetch({
      userId: useUserStore().user?.id,
      $sort: {
        createdAt: -1,
      },
    })
  },
  { immediate: true },
)
</script>

<style scoped lang="scss">
.TheNotifications {
  margin-left: 1rem;

  .TheNotifications__icon {
    --size: 1.25rem;

    &--has-unseen {
      &::before {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 0.25rem;
        width: 0.25rem;
        border-radius: 50%;
        background-color: $color-error-main;
      }
    }
  }
}

.Notification__empty {
  padding: 1rem 1.25rem;
  color: $color-primary-dark;
  font-size: 0.75rem;
  font-family: $font-nunito;
}

.Notification__loader {
  padding: 1rem 1.25rem;
  display: flex;
  justify-content: center;
}

.TheNotifications__content {
  @include customScrollbar;

  color: $color-primary-dark;
  max-height: 12rem;
}

.TheNotifications__menu-before {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $newcolor-grey-200;
}
</style>
